<template>
  <div />
</template>

<script>

export default {

  created() {
  }
}
</script>
